import "./App.css";
import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AppProvider } from "./AppContext";
import styled from "styled-components";
import Routes from "./Routes";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ConsentSnackbar from "./components/common/snackbar/ConsentSnackBar";
import { TVBrowserNoticeBar } from "makealive-component";
import BannerSnackbar from "./components/common/snackbar/BannerSnackbar";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#F44434",
    },
    secondary: {
      main: "#000000",
    },
    background: {
      default: "#000000",
      paper: "#1F1F21",
    },
    divider: "rgba(255,255,255,0.5)",
    text: {
      primary: "#FFFFFF",
      secondary: "#BCBCBC",
    },
  },
  shape: {
    borderRadius: 0,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

function App() {
  const { i18n } = useTranslation();
  const ogTitle =
    i18n.language === "en"
      ? "MakeALive｜Live Streaming Platform"
      : "MakeALive｜網上串流直播平台";
  const ogDescription =
    i18n.language === "en"
      ? "More than Live. MakeALive is a live streaming platform dedicated to providing users with interactive and realistic viewing experience."
      : "讓表演有更多可能。網上串流直播平台MakeALive，致力為用戶提供互動與實感並重的觀賞體驗。";

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta name="title" value={ogTitle} />
        <meta property="og:title" content={ogTitle} />
        <meta name="description" content={ogDescription} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:image" content="https://makealive.com/ogImage.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <AppContainer>
          <BrowserRouter>
            <AppProvider>
              <TVBrowserNoticeBar />
              <ConsentSnackbar />
              <Header />
              <BannerSnackbar />
              <Routes />
              <Footer />
            </AppProvider>
          </BrowserRouter>
        </AppContainer>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

const AppContainer = styled.div`
  width: 100%;
  max-height: -webkit-fill-available;
  text-align: center;
`;

export default App;
