import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, Navigate } from 'react-router-dom';

const LanguageWrapper = ({ language }) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if(i18n){
        localStorage.setItem("language", language);
        i18n.changeLanguage(language);
    }
  }, [language, i18n]);
  // Remove the language prefix from the path
  let newPath = location.pathname.replace(`/${language}`, '');
  if(newPath === ""){
    newPath = "/";
  }
  console.log("newPath", newPath);
  return <Navigate to={newPath} replace />;
};

export default LanguageWrapper;