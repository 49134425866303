import { useQuery } from "react-query";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { getEvent, listCartItem, checkoutCart } from "../apis";
import Loading from "../components/Loading";
import CommonText from "../components/common/typographies/CommonText";
import CommonButton from "../components/common/buttons/CommonButton";
import CommonTextButton from "../components/common/buttons/TextButton";
import dayjs from "dayjs";
import React, { useMemo, useEffect, useState, useContext } from "react";
import {
  Grid,
  Box,
  Dialog,
  Snackbar,
  SnackbarContent,
  Divider,
} from "@mui/material";
import Remind from "../assets/Remind.svg";
import TicketItem from "../pageComponents/eventDetail/TicketItem";
import SelectTicket from "../pageComponents/eventDetail/SelectTicket";
import CartItem from "../pageComponents/shoppingCart/cartItem";
import { Link } from "react-router-dom";
import Stepper from "../pageComponents/checkout/stepper";
import OrderInfo from "../pageComponents/checkout/orderInfo";
import RightArrow from "../assets/RightArrow.svg";
import DeliveryInfo from "../pageComponents/checkout/deliveryInfo";
import PaymentInfo from "../pageComponents/checkout/paymentInfo";
import { ResponsiveUI, breakpoint, WindowSize } from "../utils/responsive";
import { useTranslation, Trans } from "react-i18next";
import Alert from "../assets/Alert.svg";
import Player from "../components/Player";
import MiroValidation from "../pageComponents/checkout/MiroValidation";
import CheckBox from "../components/common/checkboxs/Checkbox";
import { CircularProgress } from "@mui/material";
import EventTnc from "../pageComponents/eventDetail/EventTnc";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AppContext } from "../AppContext";
import ExclusiveValidation from "../pageComponents/checkout/ExclusiveValidation";

const LARGE_DESKTOP_BREAKPOINT = `(min-width: 1024px)`;
const LOCAL_DIALOG_TYPE = {
  /** @deprecated */
  MIRO: "miro",
  EXCLUSIVE: "exclusive",
};

const Header = ({ t }) => {
  let navigate = useNavigate();
  return (
    <HeaderContainer container direction="row" alignItems="center">
      <Grid item xs>
        <CommonText
          align="left"
          fontSize="36px"
          lineHeight="44px"
          fontWeight="600"
        >
          {t("checkout")}
        </CommonText>
      </Grid>
      <Grid item>
        <CommonText
          fontSize="18px"
          lineHeight="28px"
          style={{
            "text-decoration-line": "underline",
            color: "#BCBCBC",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          {t("continueShopping")}
        </CommonText>
      </Grid>
    </HeaderContainer>
  );
};

function VideoView({ t }) {
  return (
    <VideoContainer
      container
      direction="column"
      style={{ width: "100%", marginTop: 30 }}
      justifyContent="center"
    >
      <CommonText
        fontSize="16px"
        lineHeight="24xpx"
        fontWeight="700"
        style={{ marginBottom: 12 }}
      >
        {t("attention")}
      </CommonText>
      <CommonText
        fontSize="16px"
        lineHeight="24xpx"
        fontWeight="700"
        style={{ marginBottom: 12 }}
      >
        {t("attention_hint")}
      </CommonText>
      <PlayerContainer>
        <Player
          title="How to Watch"
          contentId="howtowatch"
          isMultiCDN={false}
        />
      </PlayerContainer>
      <Box
        display="flex"
        flexDirection="row"
        style={{ marginTop: 12, padding: 8 }}
        bgcolor="rgba(255, 255, 255, 0.1)"
      >
        <img
          src={Alert}
          alt="Alert"
          style={{ height: 20, width: 20, marginRight: 10 }}
        />

        <CommonText fontSize="13px" lineHeight="20xpx" fontWeight="600">
          <Trans
            t={t}
            i18nKey="cannotWatchVideoDesc"
            components={[
              <Link
                to="/howtowatch"
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                <span style={{ cursor: "pointer" }} key={0} />
              </Link>,
            ]}
          />
        </CommonText>
      </Box>
    </VideoContainer>
  );
}

export default function Checkout() {
  const { t, i18n } = useTranslation("checkout");
  const { currency } = useContext(AppContext);
  const [step, setStep] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [dialog, setDialog] = useState({ open: false, type: "" });
  const [isTermsAgreed, setIsTermsArrged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const { setCart } = useContext(AppContext);
  const { isLoading, isError, data, error } = useQuery(
    ["listCartItem", currency],
    () => listCartItem()
  );
  const navigate = useNavigate();
  const isLargeDesktop = useMediaQuery(LARGE_DESKTOP_BREAKPOINT) ? true : false;
  const showOrderInfo = step === 1 && isLargeDesktop;
  const width = showOrderInfo ? "90%" : isLargeDesktop ? "55%" : "100%";
  /** * @deprecated */
  const hasMIROItem = data?.data?.hasMIROItem;
  const hasExclusiveRule =
    data?.data?.basket?.items?.findIndex(
      (each) => each?.option?.exclusiveRule != null
    ) >= 0;
  const hasItem = data?.data?.basket?.items?.length > 0;
  const isFree = hasItem && data?.data?.basket?.orderTotal == 0;

  const productExclusiveRules = useMemo(() => {
    const cartItems = data?.data?.basket?.items;
    if ( cartItems == null || cartItems.length === 0) {
      return [];
    }
    /** * @ref pagesComponents/checkout/orderinfo.js - OrderInfo() */
    const getTitle = (item) => {
      const streamStart = item?.streaming?.streamStart ? dayjs(item?.streaming?.streamStart).tz("Asia/Hong_Kong").format(t('streamStartTitleFormat')) + " " : ""
      const sponsorEn = item?.streaming?.event?.sponsorEn ? item?.streaming?.event?.sponsorEn + " " : ""
      const sponsorZh = item?.streaming?.event?.sponsorZh ? item?.streaming?.event?.sponsorZh + " " : ""
      const eventNameEn = item?.streaming?.event?.eventNameEn
      const eventNameZh = item?.streaming?.event?.eventNameZh
      return {
        en: streamStart + sponsorEn + eventNameEn,
        zh: streamStart + sponsorZh + eventNameZh,
      };
    }
    const unGroup = cartItems
      ?.filter(
        (each) =>
          each.option != null &&
          each.option.exclusiveRule &&
          each.option.exclusiveRule.canGroup === false
      )
      ?.map((each) => {
        const title = getTitle(each);
        const optionId = each.option.id;
        return each?.option?.exclusiveRule == null
          ? null
          : { ...each.option.exclusiveRule, product: [{title, optionId}] };
      });

    const canGroup = Object.values(
      cartItems
        ?.filter(
          (each) =>
            each.option != null &&
            each.option.exclusiveRule &&
            each.option.exclusiveRule.canGroup === true
        )
        ?.reduce((acc, each) => {
          const ruleId = each.option.exclusiveRule?.id;
          const title = getTitle(each)
          const optionId = each.option.id;
          if(ruleId != null){
            if(acc[ruleId] != null) {
              acc[ruleId].product.push({title, optionId});
            } else {
              acc[ruleId] = { ...each.option.exclusiveRule, product: [{title, optionId}]};
            }
        }
          return acc;
        }, {})
    );

    return canGroup.concat(unGroup).sort((a, b) => {
      return (
        cartItems.findIndex((ele) => ele.option.id === a.product[0].optionId) -
        cartItems.findIndex((ele) => ele.option.id === b.product[0].optionId)
      );
    });
  }, [data, i18n.language]);

  const lang = `${i18n.language[0].toUpperCase()}${i18n.language
    .slice(1)
    .toLowerCase()}`;

  const handleClose = () => {
    setDialog({ open: false, type: "" });
  };

  /** * @deprecated */
  const onMiroValidationSubmit = ({ value = {}, onError }) => {
    onCheckout({ extraParams: value, onError });
  };

  const onExclusiveValidationSubmit = ({ value = {}, onError }) => {
    onCheckout({ validationData: value, onError });
  };

  const onCheckout = async ({ extraParams = {}, validationData, onError }) => {
    let isFormSubmitting = false;
    try {
      setLoading(true);
      const payload = {
        paymentMethod: isFree
          ? "FREE"
          : paymentMethod === "creditCard"
          ? "GLOBAL_PAYMENT"
          : "RECON",
        validationData,
        ...extraParams,
        
      };
      const result = await checkoutCart(payload);
      if (result.success) {
        if (isFree) {
          navigate(
            "/payment-complete?order_no=" +
              result.data?.id,
            {
              replace: true,
            }
          );
        } else {
          let params = result.data;
          if (params) {
            const form = document.createElement("form");
            form.setAttribute("method", "post");
            form.setAttribute("action", "/payment");
            const hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", "data");
            hiddenField.setAttribute("value", params);
            form.appendChild(hiddenField);
            document.body.appendChild(form);
            form.submit();
            isFormSubmitting = true;
          }
        }
      } else {
        // alert(result.error.message)
        let error = null;
        if (result.error?.code == "MIRO0001") {
          error = t("miroValidationError");
        } else if (result.error?.code == "MIRO0002") {
          data?.data?.basket?.items?.forEach((product) => {
            if (product.productId == result.error.message) {
              let streamStart = dayjs(product?.streaming?.streamStart).tz("Asia/Hong_Kong");
              error = t("miroAlreadyBought", {
                month:
                  i18n.language == "zh"
                    ? streamStart.get("month") + 1
                    : streamStart.format("MMMM"),
                day: streamStart.get("date"),
              });
            }
          });
        } else if (result.error?.code == "MIRO0003") {
          error = t("userDeletedError");
        } else if (result.error?.code == "CHECKOUT0000") {
          error = t("checkoutError");
        } else if (result.error?.code == "CHECKOUT0001") {
          error = t("shoppingcartExpired");
        } else if (result.error?.code == "E0009") {
          error = t("salesEndError");
        } else if (result.error?.code == "E0012") {
          onError && onError(result.error);
        } else if (result.error?.code == "E0013") {
          onError && onError(result.error);
        } else if (result.error?.code == "E00014") {
          onError && onError(result.error);
        } else {
          error = t("networkError");
        }
        setApiError(error);
      }
      setCart(false);
    } catch (error) {
      console.log("onCheckout-error", error);
      onError && onError();
    } finally {
      if (!isFormSubmitting) {
        setLoading(false); // Only set loading to false if the form is not submitting
      }
    }
  };

  const onSelectedPaymentMethod = (pmethod) => {
    setPaymentMethod(pmethod);
  };

  return [
    <Header t={t} />,
    <StyledDivider />,
    <Grid container direction="column" style={{ width: width, margin: "auto" }}>
      <ContentContainer container direction="row">
        <LeftView item xs={showOrderInfo ? 7 : true}>
          <Grid container direction="column">
            <StepperContainer item>
              { isFree?
                  <CommonText fontSize="23px" lineHeight="27px" fontWeight="500" style={{ color: "#F44434" }}>{t("step.orderConfirmation")}</CommonText>:
                  <Stepper
                    steps={[t("step.orderConfirmation"), t("step.payment")]}
                    step={step}
                    setStep={setStep}
                  />
              }
            </StepperContainer>
            {
              step === 0 ? (
                <>
                  <OrderInfo t={t} language={i18n.language} data={data?.data || {}} />
                  <VideoView t={t} />
                  <Box border={`1px solid rgba(255,255,255,0.1)`} p={2} mt={3}>
                    <CheckBox
                      isChecked={isTermsAgreed}
                      onClick={() => setIsTermsArrged(!isTermsAgreed)}
                    >
                      <Trans
                        t={t}
                        i18nKey="checkoutTermsDesc"
                        components={[
                          <span
                            key={0}
                            onClick={() => setDialog({ open: true, type: "tnc" })}
                            style={{ textDecoration: "underline", cursor: "pointer" }}
                          />,
                          <span
                            key={1}
                            onClick={() => window.open("/tnc")}
                            style={{ cursor: "pointer", textDecoration: "underline" }}
                          />,
                        ]}
                      />
                    </CheckBox>
                  </Box>
                  <ResponsiveButton
                    onClick={() => {
                      if (isFree) {
                        if (hasExclusiveRule) {
                          setDialog({ open: true, type: LOCAL_DIALOG_TYPE.EXCLUSIVE });
                        } else {
                          onCheckout({ extraParams: {}, onError: null });
                        }
                      } else {
                        setStep(1);
                      }
                    }}
                    disabled={!isTermsAgreed || !data?.data || !hasItem}
                  >
                    {t("button.continue")}{" "}
                    <img
                      src={RightArrow}
                      alt="RightArrow"
                      style={{ marginLeft: 8 }}
                    />
                  </ResponsiveButton>
                </>
              ):(
                <StepOneContainer>
                  <PaymentInfo
                    t={t}
                    disabled={isFree}
                    onSelectedPaymentMethod={onSelectedPaymentMethod}
                    selectedPaymentMethod={paymentMethod}
                  />
                  <PaymentActionButtonsContainer container>
                    <ResponsiveButton
                      onClick={() => {
                        if (hasExclusiveRule) {
                          setDialog({ open: true, type: LOCAL_DIALOG_TYPE.EXCLUSIVE });
                        } else {
                          onCheckout({ extraParams: {}, onError: null });
                        }
                      }}
                      disabled={!paymentMethod || !hasItem}
                    >
                      {loading ? (
                        <CircularProgress size={20} style={{ color: "#fff" }} />
                      ) : (
                        <span style={{ whiteSpace: "nowrap"}}>
                          {t("button.confirmAndPay", {
                            currency: data?.data?.basket?.currency?data?.data?.basket?.currency.replace("D", "$"):"HK$",
                            price: data?.data?.basket?.orderTotal || 0,
                          })}
                        </span>
                      )}
                    </ResponsiveButton>
                    <CommonTextButton
                      style={{
                        color: "#ffffff",
                        opacity: 0.6,
                        marginTop: 30,
                      }}
                      onClick={() => setStep(step - 1)}
                    >
                      {t("button.backToStep1")}
                    </CommonTextButton>
                  </PaymentActionButtonsContainer>
                  { 
                    !(dialog.open && dialog.type === LOCAL_DIALOG_TYPE.EXCLUSIVE) && 
                    <CommonText
                      color="#F44434"
                      fontSize="16px"
                      lineHeight="24px"
                      fontWeight="400"
                      style={{ marginTop: 10, marginLeft: 8 }}
                    >
                      {apiError}
                    </CommonText>
                  }
                </StepOneContainer>
              )
            }
          </Grid>
        </LeftView>
        {showOrderInfo && (
          <>
            <Divider orientation="vertical" style={{ height: "initial" }} />
            <RightView item xs style={{ padding: `60px 0px 60px 60px` }}>
              <Box
                display="flex"
                flexDirection="row"
                style={{ marginBottom: 30 }}
              >
                <CommonText
                  align="left"
                  fontSize="18px"
                  lineHeight="26px"
                  fontWeight="700"
                  style={{ flex: 1 }}
                >
                  {t("ordeSsummary")}
                </CommonText>
                <CommonText
                  align="left"
                  fontSize="18px"
                  lineHeight="26px"
                  fontWeight="700"
                >
                  {data?.data?.basket?.items?.length} {t("item")}
                </CommonText>
              </Box>

              <OrderInfo
                t={t}
                language={i18n.language}
                data={data?.data || {}}
              />
            </RightView>
          </>
        )}
      </ContentContainer>
    </Grid>,
    <Dialog
      open={dialog.open}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        style: {
          height: "max-content",
          maxHeight: "80%",
          width: "calc(100% - 20px)"
        },
        sx: {
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#1F1F21", 
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#626264", 
          }
        }
      }}
    >
      {dialog.type === LOCAL_DIALOG_TYPE.EXCLUSIVE ? (
        <ExclusiveValidation
          validationRules={productExclusiveRules}
          basket={data?.data?.basket}
          onClose={handleClose}
          onSubmit={onExclusiveValidationSubmit}
        />
      ) : dialog.type === "tnc" ? (
        <EventTnc
          onClose={handleClose}
          data={
            data?.data?.basket?.items?.[0]?.streaming?.event?.[
              `tnc${lang}`
            ] || ""
          }
        />
      ) : null}
    </Dialog>,
  ];
}

const StyledDivider = styled(Divider)`
  && {
    margin: 0px 60px;
  }
  @media ${breakpoint.mobile} {
    && {
      margin: 0px 15px;
    }
  }
`;

const ContentContainer = styled(Grid)`
  @media ${breakpoint.mobile} {
    && {
      padding: 30px 15px;
    }
  }
  @media ${breakpoint.desktop} {
    && {
      padding: 0px 60px 60px 60px;
    }
  }
  @media ${LARGE_DESKTOP_BREAKPOINT} {
    && {
      padding: 0px 60px 60px;
      max-width: 1920px;
    }
  }
`;

const HeaderContainer = styled(Grid)`
  && {
    height: 130px;
    padding: 0px 60px;
  }

  @media ${breakpoint.mobile} {
    && {
      padding: 0px 15px;
    }
  }
`;

const StepperContainer = styled(Grid)`
  && {
    margin-bottom: 60px;
    margin-top: 40px;
  }
  @media ${breakpoint.mobile} {
    && {
      margin-bottom: 30px;
      margin-top: 0px;
    }
  }
`;

const LeftView = styled(Grid)`
`;

const RightView = styled(Grid)``;

const ResponsiveButton = styled(CommonButton)`
  && {
    margin-top: 40px;
    margin-left: 6px;

    @media ${breakpoint.mobile} {
      width: 100%;
      align-self: center;
      margin-left: 0px;
    }
    @media ${breakpoint.desktop} {
      width: 40%;
      align-self: center;
    }
    @media ${LARGE_DESKTOP_BREAKPOINT} {
      width: 40%;
      align-self: flex-end;
    }
  }
`;

const PaymentActionButtonsContainer = styled(Grid)`
  && {
    @media ${breakpoint.mobile} {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    @media ${breakpoint.desktop} {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    @media ${LARGE_DESKTOP_BREAKPOINT} {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

const VideoContainer = styled(Grid)`
  && {
    background-color: #1f1f21;
    padding: 20px;
  }
  @media ${breakpoint.mobile} {
    && {
      padding: 15px;
    }
  }
`;

const PlayerContainer = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
`

const StepOneContainer = styled.div`
@media ${breakpoint.desktop} {
    && {
      padding-right: 60px
    }
  }
`