import { useQuery } from "react-query";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import {
  getEvent,
  listEventTickets,
  addToCart,
  listTicket,
  listTicketByEvent,
} from "../apis";
import Loading from "../components/Loading";
import CommonText from "../components/common/typographies/CommonText";
import CommonButton from "../components/common/buttons/CommonButton";
import dayjs from "dayjs";
import { useEffect, useState, useContext, useMemo } from "react";
import { Grid, Box, Dialog, Snackbar, SnackbarContent } from "@mui/material";
import Remind from "../assets/Remind.svg";
import TicketItem from "../pageComponents/eventDetail/TicketItem";
import SelectTicket from "../pageComponents/eventDetail/SelectTicket";
import WhiteTicket from "../assets/WhiteTicket.svg";
import { ResponsiveUI, breakpoint, WindowSize } from "../utils/responsive";
import LeftArrow from "../assets/LeftArrow.svg";
import Alert from "../assets/Alert.svg";
import EventTnc from "../pageComponents/eventDetail/EventTnc";
import EventTncButton from "../pageComponents/eventDetail/EventTncButton";
import NoticeButton from "../pageComponents/eventDetail/NoticeButton";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Countdown from "react-countdown";
import { CircularProgress } from "@mui/material";
import { AppContext } from "../AppContext";
import PosterBgCircle from "../pageComponents/eventDetail/PosterBgCircle";
import AddToCalendarButton from "../components/addToCalendarButton";
import AddToCalendar from "@culturehq/add-to-calendar";
import "@culturehq/add-to-calendar/dist/styles.css";
import "../pageComponents/eventDetail/calendarButton.css";
import { StreamDateConverter } from "../utils/dateConverter";
import { TitleIconButtonDialog } from "../components/common/dialogs/TitleIconButtonDialog";
var isBetween = require("dayjs/plugin/isBetween");
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dateString from "../utils/dateString";
import * as arrowAnimationData from "../assets/animation/ButtonArrow.json";
import { StreamingStartedDialog } from "../pageComponents/checkout/StreamingStartedDialog";
import { Helmet } from "react-helmet";
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);
export const DESKTOP_BREAKPOINT = `(min-width: 1024px)`;

const animationDefaultOptions = {
  loop: true,
  autoplay: true,
  animationData: arrowAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const getNextOrCurrentStream = (streamings = []) => {
  let findStream = undefined;
  for (let i = 0; i < streamings.length; i++) {
    let stream = streamings[i];
    if (dayjs().isBefore(stream.streamStart)) {
      return (findStream = { ...stream, type: "beforeStart" });
    } else if (dayjs().isBetween(stream.streamStart, stream.streamEnd)) {
      return (findStream = { ...stream, type: "streaming" });
    } else if (
      dayjs().isBetween(stream.streamEnd, dayjs(stream.streamEnd).add(1, "h"))
    ) {
      return (findStream = { ...stream, type: "endStreaming" });
    }
  }
  return findStream;
};

const getSaleStatus = ({ saleStart, salesEnd, stream }) => {
  if (!saleStart) {
    return "salesStartSoon";
  }
  let saleStatus = "";
  if (saleStart && dayjs().isBefore(saleStart)) {
    saleStatus = "before";
  } else if (saleStart && dayjs().isAfter(saleStart) && stream) {
    if (stream.type === "streaming") {
      saleStatus = "streaming";
    } else if (stream.type === "beforeStart") {
      saleStatus = "buying";
    } else if (stream.type === "endStreaming") {
      saleStatus = "after";
    }
  } else {
    saleStatus = "after";
  }
  return saleStatus;
};

const getSaleButtonStatus = ({ saleStart, salesEnd }) => {
  if (!saleStart) {
    return "salesStartSoon";
  }
  let saleStatus = "";
  if (saleStart && dayjs().isBefore(saleStart)) {
    saleStatus = "before";
  } else if (salesEnd && dayjs().isAfter(salesEnd)) {
    saleStatus = "after";
  } else {
    saleStatus = "buying";
  }

  return saleStatus;
};

const StickyButton = ({
  type,
  t,
  currency,
  price,
  isSingular,
  onClick,
  event,
  myStreamingTicket,
}) => {
  const navigate = useNavigate();
  const Content = () => {
    switch (type) {
      case "buying": {
        if (myStreamingTicket) {
          const before24Hour = dayjs(myStreamingTicket.streamStart).subtract(
            1,
            "day"
          );
          if (
            dayjs(new Date()).isAfter(before24Hour) &&
            dayjs(new Date()).isBefore(myStreamingTicket.streamEnd)
          ) {
            return (
              <NewStickyLiveButtonDiv>
                {price >= 0 && (
                  <CommonButton type="display" onClick={onClick}>
                    <ButtonPriceText>
                      {t(`buyTickets_${isSingular ? "single" : "plural"}`, {
                        currency: currency.replace("D", "$"),
                        price: price,
                      })}{" "}
                    </ButtonPriceText>
                    <img
                      src={WhiteTicket}
                      alt="WhiteTicket"
                      style={{ marginLeft: 6, height: 24, width: 24 }}
                    />
                  </CommonButton>
                )}
                <CommonButton
                  style={{ boxShadow: "none", whiteSpace: "nowrap" }}
                  background_color="#B81D10"
                  hover_background_color="linear-gradient(0deg, rgba(0,0,0, 0.15), rgba(0,0,0,0.15)), rgb(184,29,16)"
                  type="display"
                  onClick={() => {
                    if (myStreamingTicket?._id) {
                      window.dataLayer.push({
                        event: "custom_event",
                        event_name: "button_click",
                        button_text: "Web enter live",
                      });
                      navigate(`/live/${myStreamingTicket._id}`);
                    }
                  }}
                >
                  {t("watchNow")}
                  <LottieButtonDiv>
                    <Lottie options={animationDefaultOptions} width="100%" />
                  </LottieButtonDiv>
                </CommonButton>
              </NewStickyLiveButtonDiv>
            );
          }
        }
        // else
        return (
          price >= 0 && (
            <FullStickyButton type="display" onClick={onClick}>
              {/* <ButtonPriceText>
              {`${t("buyTickets_prefix")} `}
              <slot className="currency">{currency.replace("D", "$")}</slot>{price}
              {isSingular ? "" : ` ${t("buyTickets_suffix")}`}
            </ButtonPriceText> */}
              {t(`buyTickets_${isSingular ? "single" : "plural"}`, {
                currency: currency.replace("D", "$"),
                price: price,
              })}{" "}
              <img
                src={WhiteTicket}
                alt="WhiteTicket"
                style={{ marginLeft: 6, height: 24, width: 24 }}
              />
            </FullStickyButton>
          )
        );
      }

      case "after": {
        if (myStreamingTicket == null) {
          return (
            <FullStickyButton
              style={{
                backgroundColor: "#121212",
                boxShadow: "none",
                color: "#bcbcbc",
                cursor: "not-allowed",
              }}
              type="display"
            >
              {t("saleEnd")}
            </FullStickyButton>
          );
        } else {
          return (
            <FullStickyButton
              style={{ boxShadow: "none", whiteSpace: "nowrap" }}
              background_color="#B81D10"
              hover_background_color="linear-gradient(0deg, rgba(0,0,0, 0.15), rgba(0,0,0,0.15)), rgb(184,29,16)"
              type="display"
              onClick={() => {
                if (myStreamingTicket?._id) {
                  window.dataLayer.push({
                    event: "custom_event",
                    event_name: "button_click",
                    button_text: "Web enter live",
                  });
                  navigate(`/live/${myStreamingTicket._id}`);
                }
              }}
            >
              {t("watchNow")}
              <LottieButtonDiv>
                <Lottie options={animationDefaultOptions} width="100%" />
              </LottieButtonDiv>
            </FullStickyButton>
          );
        }
      }

      case "before": {
        return (
          <RemindMeContainer>
            <AddToCalendar event={event}>
              <span style={{ fontWeight: 700, fontFamily: "Clash Grotesk" }}>
                {t("remindMe")}{" "}
              </span>
              <img src={Remind} alt="Remind" style={{ marginLeft: 6 }} />
            </AddToCalendar>
          </RemindMeContainer>
        );
      }

      case "salesStartSoon": {
        return null;
      }

      default: {
        return null;
      }
    }
  };

  return (
    <ReminderContainer className="EventDetailAddToCalendar">
      <Content />
    </ReminderContainer>
  );
};

const SaleStatus = ({
  t,
  language,
  status,
  streamStart,
  streamEnd,
  saleStart,
}) => {
  const fontSize = WindowSize() === "mobile" ? "20px" : "24px";
  const lineHeight = WindowSize() === "mobile" ? "1.4" : "34px";
  const countdownRenderer = ({ hours, minutes, seconds, completed, days }) => {
    if (completed) {
      return (
        <>
          <CommonText
            style={{ color: "#F44434" }}
            align="center"
            fontSize={fontSize}
            lineHeight={lineHeight}
            fontWeight="400"
          >
            {t("streaming")}
          </CommonText>
        </>
      );
    } else {
      return (
        <>
          <CommonText
            style={{ color: "#F44434" }}
            fontSize={fontSize}
            lineHeight={lineHeight}
            fontWeight="600"
          >
            <DateSpan>{days}d</DateSpan>:
            <DateSpan>{String(hours).padStart(2, "0")}h</DateSpan>:
            <DateSpan>{String(minutes).padStart(2, "0")}m</DateSpan>:
            <DateSpan>{String(seconds).padStart(2, "0")}s</DateSpan>
          </CommonText>
          <CommonText
            align="center"
            fontSize="16px"
            lineHeight="28px"
            fontWeight="400"
          >
            {t("timeLeft")}
          </CommonText>
        </>
      );
    }
  };

  const countdownRendererForStreaming = ({
    hours,
    minutes,
    seconds,
    completed,
    days,
  }) => {
    if (completed) {
      return (
        <CommonText
          style={{ color: "#F44434" }}
          align="center"
          fontSize={fontSize}
          lineHeight={lineHeight}
          fontWeight="400"
        >
          {t("liveEnd")}
        </CommonText>
      );
    } else {
      return (
        <CommonText
          style={{ color: "#F44434" }}
          align="center"
          fontSize={fontSize}
          lineHeight={lineHeight}
          fontWeight="400"
        >
          {t("streaming")}
        </CommonText>
      );
    }
  };

  switch (status) {
    case "before": {
      return (
        <>
          {saleStart && (
            <CommonText
              style={{ color: "#F44434" }}
              align="center"
              fontSize={fontSize}
              lineHeight={lineHeight}
              fontWeight="600"
            >
              {saleStart && dateString(saleStart, language)}
            </CommonText>
          )}
          <CommonText
            align="center"
            fontSize="16px"
            lineHeight="28px"
            fontWeight="400"
          >
            {t("startSale")}
          </CommonText>
        </>
      );
    }

    case "buying": {
      return <Countdown date={streamStart} renderer={countdownRenderer} />;
    }

    case "streaming": {
      return (
        <Countdown date={streamEnd} renderer={countdownRendererForStreaming} />
      );
    }

    case "after": {
      return (
        <CommonText
          style={{ color: "#F44434" }}
          align="center"
          fontSize={fontSize}
          lineHeight={lineHeight}
          fontWeight="400"
        >
          {t("liveEnd")}
        </CommonText>
      );
    }

    case "salesStartSoon": {
      return (
        <CommonText
          style={{ color: "#F44434" }}
          align="center"
          fontSize={fontSize}
          lineHeight={lineHeight}
          fontWeight="400"
        >
          {t("salesStartSoon")}
        </CommonText>
      );
    }

    default: {
      return null;
    }
  }
};

export default function EventDetail() {
  const { t, i18n } = useTranslation("eventDetail");
  const { user, requestSignIn, cart, setCart, currency } =
    useContext(AppContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [dialog, setDialog] = useState({ open: false, type: "" });
  const [alertOpen, setAlertOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [snackbar, setSnackbar] = useState({ open: false });
  const [isStreamingStarted, setIsStreamingStarted] = useState(false);
  const { isLoading, isError, data, error } = useQuery(["getEvent", id], () =>
    getEvent(id)
  );
  const {
    isLoading: isEventTicketsLoading,
    isError: isEventTicketsError,
    data: eventTicketsData,
    error: eventTicketsError,
  } = useQuery(["listEventTickets", id, currency, i18n.language], () =>
    listEventTickets(id, currency, i18n.language)
  );
  const {
    isLoading: isTicketLoading,
    isError: isTicketError,
    data: ticketData,
    error: ticketError,
  } = useQuery("listTicket", () => listTicketByEvent(id));
  const event = data?.data;
  const eventTickets = eventTicketsData?.data || [];
  const getFirstTicket = useMemo(() => {
    if (eventTickets) {
      for (let i = 0; i < eventTickets.length; i++) {
        if (eventTickets[i].productInfo) return eventTickets[i];
      }
    }
    return null;
  }, [eventTickets]);

  useEffect(() => {
    if (data && data.success === false) {
      navigate("/");
    }
  }, [data]);

  const purchasedProductIds = useMemo(() => {
    if (ticketData?.data)
      return [
        ...ticketData?.data.ongoing.map((ticket) => ticket.product),
        ...ticketData?.data.past.map((ticket) => ticket.product),
      ];
    return [];
  }, [ticketData]);

  const lowestPrice = useMemo(() => {
    let price = -1;
    let currentTime = dayjs();
    let hasTicketToPurchase = false;
    if (eventTickets) {
      for (let i = 0; i < eventTickets.length; i++) {
        if (eventTickets[i].productInfo) {
          // if (!hasTicketToPurchase){
          //   let salesStart = dayjs(eventTickets[i].productInfo.startSalesAt);
          //   let salesEnd = dayjs(eventTickets[i].productInfo.endSalesAt);
          //   if (currentTime > salesStart && currentTime < salesEnd && purchasedProductIds.indexOf(eventTickets[i].product) < 0) {
          //     hasTicketToPurchase = true;
          //   }
          // }
          for (let j = 0; j < eventTickets[i].productInfo.options.length; j++) {
            if (price < 0)
              price =
                eventTickets[i].productInfo.options[j].discountedPrice != null
                  ? eventTickets[i].productInfo.options[j].discountedPrice
                  : eventTickets[i].productInfo.options[j].price;
            else
              price = Math.min(
                price,
                eventTickets[i].productInfo.options[j].discountedPrice != null
                  ? eventTickets[i].productInfo.options[j].discountedPrice
                  : eventTickets[i].productInfo.options[j].price
              );
          }
        }
      }
    }
    // if (hasTicketToPurchase)
    return price == -1 ? 0 : price;
    // else
    // return -1;
  }, [eventTickets, ticketData]);

  const myOngoingTickets = ticketData?.data.ongoing ?? [];
  const lang = `${i18n.language[0].toUpperCase()}${i18n.language
    .slice(1)
    .toLowerCase()}`;
  const productDescription =
    getFirstTicket?.productInfo?.description[i18n.language];
  const productOptions = getFirstTicket?.productInfo?.options || [];
  const saleStart = event?.salesStart && dayjs(event?.salesStart);
  const salesEnd = event?.salesEnd && dayjs(event?.salesEnd);
  const stream = getNextOrCurrentStream(eventTicketsData?.data);
  const saleStatus = getSaleStatus({ saleStart, salesEnd, stream });
  const saleButtonStatus = getSaleButtonStatus({ saleStart, salesEnd });
  const isMobile = WindowSize() == "mobile";

  const handleClose = () => {
    setDialog({ open: false });
  };

  const handleOpen = (type) => {
    setDialog({ open: true, type });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, type: "" });
  };

  const handleOnClickAddToCart = ({ productId, productOptionId, onError }) => {
    if (!productId || !productOptionId) return;
    const onSuccess = () => {
      setSnackbar({ open: true });
      handleClose();
    };
    requestAddToCart({
      productId,
      productOptionId,
      replace: false,
      onSuccess,
      onError,
    });
  };

  const handleOnClickBuy = ({ productId, productOptionId, onError }) => {
    if (!productId || !productOptionId) return;
    const onSuccess = () => {
      navigate(`/checkout`);
    };
    requestAddToCart({
      productId,
      productOptionId,
      replace: true,
      onSuccess,
      onError,
    });
  };

  const requestAddToCart = async ({
    productId,
    productOptionId,
    replace,
    onSuccess,
    onError,
  }) => {
    if (!user) {
      onError && onError();
      return requestSignIn && requestSignIn();
    }
    try {
      const response = await addToCart(productId, productOptionId, replace);
      if (response.success) {
        setCart(true);
        onSuccess && onSuccess();
        window.dataLayer.push({
          event: "custom_event",
          event_name: "add_to_cart",
          currency: response.data?.currency,
          value: response.data?.orderTotal,
          items: response.data?.items,
        });
      } else {
        setAlertOpen(true);
        setErrorMsg(
          (["BUY0006", "CART0002"].includes(response?.error?.code)
            ? t(`error.${response.error.code}`)
            : ["BUY0000", "BUY0002", "BUY0005", "CART0003", "E0009"].includes(
                response?.error?.code
              )
            ? t(`error.CART0003`)
            : response?.error?.message || "") +
            `\n\n${t("errorCode")}: ` +
            response?.error?.code
        );
        onError && onError();
      }
    } catch (error) {
      console.log("requestAddToCart-error", error);
      onError && onError();
    }
  };

  const EventPosterView = () => {
    const streamStart = stream?.streamStart ? dayjs(stream.streamStart) : null;
    const streamEnd = stream?.streamEnd ? dayjs(stream.streamEnd) : null;
    const isNoPrev = window.history.state.idx === 0;

    return (
      <>
        <PosterView>
          {/* <FixedImg src={event?.poster} /> */}
          <PosterHeader>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              width="100%"
            >
              <SaleStatus
                t={t}
                language={i18n.language}
                status={saleStatus}
                streamStart={streamStart}
                streamEnd={streamEnd}
                saleStart={saleStart}
              />
            </Box>
          </PosterHeader>
          <PosterImg src={event?.poster} />
          <PosterBgCircle />
          <PosterBgView style={{ backgroundImage: `url(${event?.poster})` }} />
        </PosterView>
        <RightArrowImg
          src={LeftArrow}
          alt="LeftArrow"
          onClick={() => {
            if (isNoPrev) {
              navigate("/");
            } else {
              navigate(-1);
            }
          }}
        />
      </>
    );
  };

  const EventContentView = () => {
    return (
      <ContentView>
        {event?.[`noticeTitle${lang}`] ? (
          <NoticeButton
            label={event?.[`noticeTitle${lang}`]}
            content={event?.[`noticeContent${lang}`]}
            style={{ marginBottom: 30 }}
          />
        ) : null}
        <CommonText
          style={{ textAlign: "left", marginBottom: 5 }}
          fontSize="16px"
          lFontSize="18px"
          lineHeight="1.2"
        >
          {event?.[`sponsor${lang}`]}
        </CommonText>
        <CommonText
          style={{ textAlign: "left", marginBottom: 5 }}
          fontSize="30px"
          lineHeight="44px"
        >
          {event?.[`eventName${lang}`]}
        </CommonText>
        <CommonText
          style={{
            whiteSpace: "pre-line",
            textAlign: "left",
            marginBottom: 30,
            color: "#BCBCBC",
          }}
          fontSize="16px"
          lineHeight="28px"
        >
          {event?.[`info${lang}`]}
        </CommonText>
        <CommonText
          style={{ textAlign: "left", marginBottom: 15, color: "#BCBCBC" }}
          fontSize="14px"
          lineHeight="20px"
        >
          {t("streamingDateAndTime")}
        </CommonText>
        {/* <StreamDateConverter i18n={i18n} streams={eventTickets} displayLimit={3}/> */}
        {eventTickets.map((item) => {
          const streamStart = item?.streamStart
            ? dayjs(item.streamStart)
            : null;
          const streamEnd = item?.streamEnd ? dayjs(item.streamEnd) : null;
          if (!streamStart) return null;
          return (
            <CommonText
              style={{ textAlign: "left", marginBottom: 6 }}
              fontSize="18px"
              lineHeight="27px"
              fontWeight="400"
            >
              {dateString(streamStart, i18n.language)}
            </CommonText>
          );
        })}
        <CommonText
          style={{
            textAlign: "left",
            margin: `30px 0px 15px 0px`,
            color: "#BCBCBC",
          }}
          fontSize="14px"
          lineHeight="20px"
        >
          {t("steamedTickets")}
        </CommonText>
        <ProductDescription
          dangerouslySetInnerHTML={{ __html: productDescription }}
        />
        <CommonText style={{ marginTop: 60, color: "#BCBCBC" }}>
          {t("organisers")}
        </CommonText>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          style={{ width: "100%", marginBottom: 40 }}
        >
          {event?.organiserEn && (
            <img
              src={event.organiserEn}
              style={{ width: "100%", objectFit: "contain" }}
            />
          )}
        </Box>
        <EventTncButton onClick={() => handleOpen("tnc")} />
      </ContentView>
    );
  };

  const handleOnClickStickyBuyButton = (checkStreamStarted) => {
    if (eventTickets.length === 1 && productOptions.length === 1) {
      const product = eventTickets?.[0] || {};
      if (
        checkStreamStarted &&
        product.streamStart &&
        dayjs().isAfter(dayjs(product.streamStart))
      ) {
        setIsStreamingStarted(true);
        return;
      }
      handleOnClickBuy({
        productId: product.product,
        productOptionId: productOptions[0]?.id,
      });
      return;
    }
    handleOpen("ticket");
  };

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <>
      <Helmet>
        <meta property="og:image" content={`${event?.poster}?og=true`} />
        <meta
          name="title"
          content={`${t("ogTitle_prefix")}${event[`eventName${lang}`]}`}
        />
        <meta
          property="og:title"
          content={`${t("ogTitle_prefix")}${event[`eventName${lang}`]}`}
        />
        <meta name="description" content={`${event[`info${lang}`]}`} />
        <meta property="og:description" content={`${event[`info${lang}`]}`} />
      </Helmet>
      <Container>
        <EventPosterView />
        <EventContentView />
        <StickyButton
          type={saleButtonStatus}
          t={t}
          onClick={() => handleOnClickStickyBuyButton(true)}
          myStreamingTicket={myOngoingTickets?.[0]}
          currency={getFirstTicket?.productInfo?.currency || "HKD"}
          price={lowestPrice}
          isSingular={eventTickets.length === 1 && productOptions.length === 1}
          event={
            event
              ? {
                  name: event?.[`eventName${lang}`] + " " + t("ticketSale"),
                  details: `https://www.makealive.com`,
                  startsAt: event?.salesStart,
                  endsAt: event?.salesStart
                    ? dayjs(event?.salesStart).add(6, "hour")
                    : null,
                }
              : null
          }
        />
      </Container>
      <Dialog
        open={dialog.open}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          style: {
            height: "80%",
            width: isMobile ? "100%" : null,
            margin: isMobile ? 10 : null,
          },
        }}
        aria-labelledby="select-ticket-draggable-dialog-title"
      >
        {dialog.type === "ticket" && (
          <SelectTicket
            onClose={handleClose}
            purchasedProductIds={purchasedProductIds}
            products={eventTickets || []}
            onClickAddToCart={handleOnClickAddToCart}
            onClickBuy={handleOnClickBuy}
          />
        )}
        {dialog.type === "tnc" && (
          <EventTnc onClose={handleClose} data={event?.[`tnc${lang}`]} />
        )}
      </Dialog>
      <TitleIconButtonDialog
        visible={alertOpen}
        setVisible={setAlertOpen}
        title={t("alertDialog.title")}
        body={errorMsg}
        ImgComp={() => <img src={Alert} style={{ height: 60, width: 60 }} />}
        buttonText={t("common.ok")}
      />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbar.open}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <SnackbarContent
          style={{ backgroundColor: "#1F1F21", justifyContent: "center" }}
          message={<SnackbarSpan>{t("addedToCart")}</SnackbarSpan>}
        />
      </Snackbar>
      <StreamingStartedDialog
        visible={isStreamingStarted}
        setVisible={setIsStreamingStarted}
        onClick={() => handleOnClickStickyBuyButton(false)}
      />
    </>
  );
}

const Container = styled.div`
  @media ${breakpoint.mobile} {
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow-y: hidden;
  }
  @media ${breakpoint.desktop} {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  @media ${DESKTOP_BREAKPOINT} {
    display: flex;
    width: 100%;
    flex-direction: row;
  }
`;

const PosterView = styled.div`
  display: flex;
  flex-direction: column;
  @media ${breakpoint.mobile} {
    width: 100%;
    height: calc(100vh - 75px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: inherit;
  }
  @media ${breakpoint.desktop} {
    width: 100%;
    height: calc(100vh - 75px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  @media ${DESKTOP_BREAKPOINT} {
    width: 50%;
    height: calc(100vh - 88px);
    z-index: -99;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 75px;
  }
`;

const PosterBgView = styled.div`
  width: 100%;
  height: 70vh;
  min-height: 520px;
  display: block;
  z-index: -99;
  filter: blur(30px);
  -webkit-filter: blur(30px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const PosterImg = styled.img`
  && {
    position: absolute;
    z-index: 9;
    object-fit: contain;
    height: 70vh;
    @media ${breakpoint.mobile} {
      width: 80%;
      max-width: 430px;
      top: 23%;
    }
    @media ${breakpoint.desktop} {
      top: 15%;
      width: 410px;
    }
    @media ${DESKTOP_BREAKPOINT} {
      top: 15%;
      width: 70vh;
      min-width: 200px;
      min-height: 200px;
    }
  }
`;

const PosterHeader = styled.div`
  margin-bottom: 38px;
  @media ${breakpoint.mobile} {
    margin-top: 20px;
  }
`;
const ProductDescription = styled.div`
  text-align: left;
  table {
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding: 15px;
    width: 100%;
    margin-bottom: 15px;
    font-family: Clash Grotesk;
    .title {
      display: flex;
      img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
  }
`;
const ContentView = styled.div`
  @media ${breakpoint.mobile} {
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 60px;
  }
  @media ${breakpoint.desktop} {
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 3%;
  }
  @media ${DESKTOP_BREAKPOINT} {
    flex: 1;
    padding: 40px;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
    position: relative;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const TicketsContainer = styled(Grid)`
  @media ${breakpoint.mobile} {
    && {
      flex-direction: column;
    }
  }

  @media ${breakpoint.desktop} {
    && {
      flex-direction: row;
    }
  }

  @media ${DESKTOP_BREAKPOINT} {
    && {
      flex-direction: row;
    }
  }
`;

const ReminderContainer = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10;
  @media ${breakpoint.mobile} {
    && {
      width: 100%;
      padding: 15px;
    }
  }
  @media ${breakpoint.desktop} {
    && {
      /* height: 60px; */
      /* width: 430px; */
    }
  }

  @media ${DESKTOP_BREAKPOINT} {
    && {
      /* height: 60px; */
      /* width: 430px; */
    }
  }
`;

const SnackbarSpan = styled.span`
  margin: auto;
  text-align: center !important;
  color: rgba(255, 255, 255, 0.8);
`;

const DateSpan = styled.span`
  margin: 0px 6px;
`;

const RightArrowImg = styled.img`
  cursor: pointer;
  margin: 20px;
  z-index: 9;
  @media ${breakpoint.mobile} {
    position: absolute;
  }

  @media ${breakpoint.desktop} {
    position: absolute;
  }

  @media ${DESKTOP_BREAKPOINT} {
    position: fixed;
  }
`;

const LottieButtonDiv = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 5px;
`;

const ButtonPriceText = styled.span`
  & .currency {
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

const NewStickyLiveButtonDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  & > button:nth-child(1) {
    height: 60px;
  }
  & > button:nth-child(2) {
    height: 60px;
  }

  @media ${breakpoint.desktop} {
    flex-direction: row;
    gap: 0px;
    & > button:nth-child(1) {
      width: max-content !important;
      min-width: 311px;
      flex-shrink: 0;
    }
    & > button:nth-child(2) {
      width: max-content !important;
      min-width: 171px;
      flex-shrink: 0;
    }
  }
`;

const RemindMeContainer = styled.div`
  @media ${breakpoint.desktop} {
    min-width: 430px;
  }
`;

const FullStickyButton = styled(CommonButton)`
  && {
    width: 100%;
    height: 60px;
    font-size: 18px;
    font-family: "Clash Grotesk";
    @media ${breakpoint.desktop} {
      min-width: 430px;
    }
  }
`;
